import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/501/',
    component: ComponentCreator('/501/', '694'),
    exact: true
  },
  {
    path: '/articles/',
    component: ComponentCreator('/articles/', '580'),
    exact: true
  },
  {
    path: '/articles/2024/07/09/diskusija-lj-nj-cyrl/',
    component: ComponentCreator('/articles/2024/07/09/diskusija-lj-nj-cyrl/', '56b'),
    exact: true
  },
  {
    path: '/articles/2024/07/09/diskusija-lj-nj/',
    component: ComponentCreator('/articles/2024/07/09/diskusija-lj-nj/', 'bc3'),
    exact: true
  },
  {
    path: '/articles/archive/',
    component: ComponentCreator('/articles/archive/', '05e'),
    exact: true
  },
  {
    path: '/',
    component: ComponentCreator('/', '070'),
    exact: true
  },
  {
    path: '/',
    component: ComponentCreator('/', '99f'),
    routes: [
      {
        path: '/',
        component: ComponentCreator('/', '3c6'),
        routes: [
          {
            path: '/tags/',
            component: ComponentCreator('/tags/', 'ff9'),
            exact: true
          },
          {
            path: '/tags/adjectives/',
            component: ComponentCreator('/tags/adjectives/', 'b4f'),
            exact: true
          },
          {
            path: '/tags/auxiliary-languages/',
            component: ComponentCreator('/tags/auxiliary-languages/', '393'),
            exact: true
          },
          {
            path: '/tags/communication/',
            component: ComponentCreator('/tags/communication/', 'c71'),
            exact: true
          },
          {
            path: '/tags/comparative/',
            component: ComponentCreator('/tags/comparative/', 'c04'),
            exact: true
          },
          {
            path: '/tags/conjunctions/',
            component: ComponentCreator('/tags/conjunctions/', '282'),
            exact: true
          },
          {
            path: '/tags/conlanging/',
            component: ComponentCreator('/tags/conlanging/', '01b'),
            exact: true
          },
          {
            path: '/tags/constructed-languages/',
            component: ComponentCreator('/tags/constructed-languages/', 'f49'),
            exact: true
          },
          {
            path: '/tags/cyrillic/',
            component: ComponentCreator('/tags/cyrillic/', '78e'),
            exact: true
          },
          {
            path: '/tags/degrees-of-comparison/',
            component: ComponentCreator('/tags/degrees-of-comparison/', 'ca3'),
            exact: true
          },
          {
            path: '/tags/derivation/',
            component: ComponentCreator('/tags/derivation/', 'a14'),
            exact: true
          },
          {
            path: '/tags/design-criteria/',
            component: ComponentCreator('/tags/design-criteria/', '83f'),
            exact: true
          },
          {
            path: '/tags/dialects/',
            component: ComponentCreator('/tags/dialects/', '657'),
            exact: true
          },
          {
            path: '/tags/flavourisation/',
            component: ComponentCreator('/tags/flavourisation/', 'a74'),
            exact: true
          },
          {
            path: '/tags/game/',
            component: ComponentCreator('/tags/game/', 'f4f'),
            exact: true
          },
          {
            path: '/tags/grammar/',
            component: ComponentCreator('/tags/grammar/', '986'),
            exact: true
          },
          {
            path: '/tags/history/',
            component: ComponentCreator('/tags/history/', 'ea7'),
            exact: true
          },
          {
            path: '/tags/hutsul/',
            component: ComponentCreator('/tags/hutsul/', 'e37'),
            exact: true
          },
          {
            path: '/tags/inflection/',
            component: ComponentCreator('/tags/inflection/', 'af7'),
            exact: true
          },
          {
            path: '/tags/international-vocabulary/',
            component: ComponentCreator('/tags/international-vocabulary/', 'f6c'),
            exact: true
          },
          {
            path: '/tags/interslavic/',
            component: ComponentCreator('/tags/interslavic/', '343'),
            exact: true
          },
          {
            path: '/tags/introduction/',
            component: ComponentCreator('/tags/introduction/', 'a00'),
            exact: true
          },
          {
            path: '/tags/kashubian/',
            component: ComponentCreator('/tags/kashubian/', '4dc'),
            exact: true
          },
          {
            path: '/tags/language-comparison/',
            component: ComponentCreator('/tags/language-comparison/', '0d2'),
            exact: true
          },
          {
            path: '/tags/language-history/',
            component: ComponentCreator('/tags/language-history/', 'f6b'),
            exact: true
          },
          {
            path: '/tags/language-learning/',
            component: ComponentCreator('/tags/language-learning/', '1c3'),
            exact: true
          },
          {
            path: '/tags/language/',
            component: ComponentCreator('/tags/language/', '882'),
            exact: true
          },
          {
            path: '/tags/latin/',
            component: ComponentCreator('/tags/latin/', '5c7'),
            exact: true
          },
          {
            path: '/tags/lemko-rusyn/',
            component: ComponentCreator('/tags/lemko-rusyn/', '0bb'),
            exact: true
          },
          {
            path: '/tags/linguistics/',
            component: ComponentCreator('/tags/linguistics/', '697'),
            exact: true
          },
          {
            path: '/tags/mary-russel-mitford/',
            component: ComponentCreator('/tags/mary-russel-mitford/', 'db3'),
            exact: true
          },
          {
            path: '/tags/nouns/',
            component: ComponentCreator('/tags/nouns/', '774'),
            exact: true
          },
          {
            path: '/tags/numbers/',
            component: ComponentCreator('/tags/numbers/', '94d'),
            exact: true
          },
          {
            path: '/tags/numerals/',
            component: ComponentCreator('/tags/numerals/', '5d1'),
            exact: true
          },
          {
            path: '/tags/old-church-slavonic/',
            component: ComponentCreator('/tags/old-church-slavonic/', 'a78'),
            exact: true
          },
          {
            path: '/tags/old-novgorodian/',
            component: ComponentCreator('/tags/old-novgorodian/', '158'),
            exact: true
          },
          {
            path: '/tags/orthography/',
            component: ComponentCreator('/tags/orthography/', 'c74'),
            exact: true
          },
          {
            path: '/tags/pan-slavic-relay/',
            component: ComponentCreator('/tags/pan-slavic-relay/', '393'),
            exact: true
          },
          {
            path: '/tags/pan-slavic/',
            component: ComponentCreator('/tags/pan-slavic/', '59e'),
            exact: true
          },
          {
            path: '/tags/pan-slavism/',
            component: ComponentCreator('/tags/pan-slavism/', 'f92'),
            exact: true
          },
          {
            path: '/tags/personal-pronouns/',
            component: ComponentCreator('/tags/personal-pronouns/', '707'),
            exact: true
          },
          {
            path: '/tags/phonology/',
            component: ComponentCreator('/tags/phonology/', '0cf'),
            exact: true
          },
          {
            path: '/tags/podlachian/',
            component: ComponentCreator('/tags/podlachian/', '4a1'),
            exact: true
          },
          {
            path: '/tags/poetry/',
            component: ComponentCreator('/tags/poetry/', 'a2e'),
            exact: true
          },
          {
            path: '/tags/polabian/',
            component: ComponentCreator('/tags/polabian/', '8d6'),
            exact: true
          },
          {
            path: '/tags/prepositions/',
            component: ComponentCreator('/tags/prepositions/', '700'),
            exact: true
          },
          {
            path: '/tags/pronouns/',
            component: ComponentCreator('/tags/pronouns/', '355'),
            exact: true
          },
          {
            path: '/tags/pronunciation/',
            component: ComponentCreator('/tags/pronunciation/', 'd8d'),
            exact: true
          },
          {
            path: '/tags/proto-slavic/',
            component: ComponentCreator('/tags/proto-slavic/', '427'),
            exact: true
          },
          {
            path: '/tags/relay/',
            component: ComponentCreator('/tags/relay/', '98c'),
            exact: true
          },
          {
            path: '/tags/simplified-grammar/',
            component: ComponentCreator('/tags/simplified-grammar/', '69a'),
            exact: true
          },
          {
            path: '/tags/slavic-languages/',
            component: ComponentCreator('/tags/slavic-languages/', 'd29'),
            exact: true
          },
          {
            path: '/tags/slavski/',
            component: ComponentCreator('/tags/slavski/', '7fa'),
            exact: true
          },
          {
            path: '/tags/slovianski/',
            component: ComponentCreator('/tags/slovianski/', 'cdf'),
            exact: true
          },
          {
            path: '/tags/slovianto/',
            component: ComponentCreator('/tags/slovianto/', 'd4a'),
            exact: true
          },
          {
            path: '/tags/slovio/',
            component: ComponentCreator('/tags/slovio/', 'b11'),
            exact: true
          },
          {
            path: '/tags/slovioski/',
            component: ComponentCreator('/tags/slovioski/', 'b97'),
            exact: true
          },
          {
            path: '/tags/spelling/',
            component: ComponentCreator('/tags/spelling/', '5b9'),
            exact: true
          },
          {
            path: '/tags/syntax/',
            component: ComponentCreator('/tags/syntax/', '70b'),
            exact: true
          },
          {
            path: '/tags/translation/',
            component: ComponentCreator('/tags/translation/', 'c08'),
            exact: true
          },
          {
            path: '/tags/verbs/',
            component: ComponentCreator('/tags/verbs/', '024'),
            exact: true
          },
          {
            path: '/tags/word-formation/',
            component: ComponentCreator('/tags/word-formation/', '66e'),
            exact: true
          },
          {
            path: '/',
            component: ComponentCreator('/', '500'),
            routes: [
              {
                path: '/learn/faq/',
                component: ComponentCreator('/learn/faq/', '624'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/',
                component: ComponentCreator('/learn/grammar/', '512'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/adjectives/',
                component: ComponentCreator('/learn/grammar/adjectives/', 'f5f'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/conjunctions/',
                component: ComponentCreator('/learn/grammar/conjunctions/', '91b'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/nouns/',
                component: ComponentCreator('/learn/grammar/nouns/', 'c80'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/numerals/',
                component: ComponentCreator('/learn/grammar/numerals/', '265'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/prepositions/',
                component: ComponentCreator('/learn/grammar/prepositions/', '1ab'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/pronouns/',
                component: ComponentCreator('/learn/grammar/pronouns/', 'e22'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/syntax/',
                component: ComponentCreator('/learn/grammar/syntax/', 'afe'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/grammar/verbs/',
                component: ComponentCreator('/learn/grammar/verbs/', '3a6'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/introduction/',
                component: ComponentCreator('/learn/introduction/', '5bc'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/introduction/constructed-slavic-languages/',
                component: ComponentCreator('/learn/introduction/constructed-slavic-languages/', '292'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/introduction/design-criteria/',
                component: ComponentCreator('/learn/introduction/design-criteria/', '778'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/introduction/history/',
                component: ComponentCreator('/learn/introduction/history/', '3cc'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/introduction/language-comparison/',
                component: ComponentCreator('/learn/introduction/language-comparison/', 'dd6'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/misc/numbers-1-10/',
                component: ComponentCreator('/learn/misc/numbers-1-10/', 'ae9'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/misc/pan-slavic-relay/',
                component: ComponentCreator('/learn/misc/pan-slavic-relay/', 'e86'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/misc/personal-pronouns/',
                component: ComponentCreator('/learn/misc/personal-pronouns/', 'cac'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/orthography/',
                component: ComponentCreator('/learn/orthography/', 'b09'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/phonology/',
                component: ComponentCreator('/learn/phonology/', 'bf4'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/pronunciation/',
                component: ComponentCreator('/learn/pronunciation/', 'cc4'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/simple-grammar/',
                component: ComponentCreator('/learn/simple-grammar/', '44d'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/simple-grammar/level-1/',
                component: ComponentCreator('/learn/simple-grammar/level-1/', '65a'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/simple-grammar/level-2/',
                component: ComponentCreator('/learn/simple-grammar/level-2/', 'b12'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/vocabulary/derivation/',
                component: ComponentCreator('/learn/vocabulary/derivation/', 'c6f'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/vocabulary/flavourisation/',
                component: ComponentCreator('/learn/vocabulary/flavourisation/', '4a1'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/learn/vocabulary/word-formation/',
                component: ComponentCreator('/learn/vocabulary/word-formation/', '16e'),
                exact: true,
                sidebar: "learnSidebar"
              },
              {
                path: '/resources/',
                component: ComponentCreator('/resources/', '36c'),
                exact: true,
                sidebar: "resourcesSidebar"
              },
              {
                path: '/resources/applets/',
                component: ComponentCreator('/resources/applets/', '065'),
                exact: true,
                sidebar: "resourcesSidebar"
              },
              {
                path: '/resources/applets/intelligibility/',
                component: ComponentCreator('/resources/applets/intelligibility/', 'e3e'),
                exact: true,
                sidebar: "resourcesSidebar"
              },
              {
                path: '/resources/applets/transliterator/',
                component: ComponentCreator('/resources/applets/transliterator/', '2be'),
                exact: true,
                sidebar: "resourcesSidebar"
              },
              {
                path: '/resources/keyboards/',
                component: ComponentCreator('/resources/keyboards/', '56c'),
                exact: true,
                sidebar: "resourcesSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
