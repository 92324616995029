export default {
  "03e1768c": [() => import(/* webpackChunkName: "03e1768c" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavic-relay-52e.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavic-relay-52e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavic-relay-52e.json")],
  "04913dad": [() => import(/* webpackChunkName: "04913dad" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-pronouns-711.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-pronouns-711.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-pronouns-711.json")],
  "08806307": [() => import(/* webpackChunkName: "08806307" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-numerals-42a.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-numerals-42a.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-numerals-42a.json")],
  "0a39fe77": [() => import(/* webpackChunkName: "0a39fe77" */ "@site/docs/learn/misc/pan-slavic-relay.md"), "@site/docs/learn/misc/pan-slavic-relay.md", require.resolveWeak("@site/docs/learn/misc/pan-slavic-relay.md")],
  "0a77bfff": [() => import(/* webpackChunkName: "0a77bfff" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-verbs-2c3.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-verbs-2c3.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-verbs-2c3.json")],
  "0d21a772": [() => import(/* webpackChunkName: "0d21a772" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-slovianski-0df.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-slovianski-0df.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-slovianski-0df.json")],
  "0e5efe55": [() => import(/* webpackChunkName: "0e5efe55" */ "@site/docs/learn/grammar/index.md"), "@site/docs/learn/grammar/index.md", require.resolveWeak("@site/docs/learn/grammar/index.md")],
  "1044cb75": [() => import(/* webpackChunkName: "1044cb75" */ "@site/docs/learn/introduction/design-criteria.md"), "@site/docs/learn/introduction/design-criteria.md", require.resolveWeak("@site/docs/learn/introduction/design-criteria.md")],
  "1067bd84": [() => import(/* webpackChunkName: "1067bd84" */ "@site/docs/learn/faq.md"), "@site/docs/learn/faq.md", require.resolveWeak("@site/docs/learn/faq.md")],
  "14cf2a72": [() => import(/* webpackChunkName: "14cf2a72" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-prepositions-805.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-prepositions-805.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-prepositions-805.json")],
  "1518e122": [() => import(/* webpackChunkName: "1518e122" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-old-novgorodian-84e.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-old-novgorodian-84e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-old-novgorodian-84e.json")],
  "17896441": [() => import(/* webpackChunkName: "17896441" */ "@theme/DocItem"), "@theme/DocItem", require.resolveWeak("@theme/DocItem")],
  "191a62d6": [() => import(/* webpackChunkName: "191a62d6" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-flavourisation-c13.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-flavourisation-c13.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-flavourisation-c13.json")],
  "196d5c80": [() => import(/* webpackChunkName: "196d5c80" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-conlanging-a99.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-conlanging-a99.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-conlanging-a99.json")],
  "1a4aaeaf": [() => import(/* webpackChunkName: "1a4aaeaf" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-lemko-rusyn-ff2.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-lemko-rusyn-ff2.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-lemko-rusyn-ff2.json")],
  "1a90b669": [() => import(/* webpackChunkName: "1a90b669" */ "@site/articles/2024-07-09-diskusija-lj-nj.md"), "@site/articles/2024-07-09-diskusija-lj-nj.md", require.resolveWeak("@site/articles/2024-07-09-diskusija-lj-nj.md")],
  "1c84a599": [() => import(/* webpackChunkName: "1c84a599" */ "@site/docs/learn/grammar/verbs.md"), "@site/docs/learn/grammar/verbs.md", require.resolveWeak("@site/docs/learn/grammar/verbs.md")],
  "1d262808": [() => import(/* webpackChunkName: "1d262808" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-translation-54b.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-translation-54b.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-translation-54b.json")],
  "1e08adb1": [() => import(/* webpackChunkName: "1e08adb1" */ "@site/articles/2024-07-09-diskusija-lj-nj-cyrl.md?truncated=true"), "@site/articles/2024-07-09-diskusija-lj-nj-cyrl.md?truncated=true", require.resolveWeak("@site/articles/2024-07-09-diskusija-lj-nj-cyrl.md?truncated=true")],
  "1f391b9e": [() => import(/* webpackChunkName: "1f391b9e" */ "@theme/MDXPage"), "@theme/MDXPage", require.resolveWeak("@theme/MDXPage")],
  "2111da52": [() => import(/* webpackChunkName: "2111da52" */ "@site/docs/learn/introduction/language-comparison.md"), "@site/docs/learn/introduction/language-comparison.md", require.resolveWeak("@site/docs/learn/introduction/language-comparison.md")],
  "22dd74f7": [() => import(/* webpackChunkName: "22dd74f7" */ "@generated/docusaurus-plugin-content-docs/default/p/index-466.json"), "@generated/docusaurus-plugin-content-docs/default/p/index-466.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/index-466.json")],
  "23c4d3ab": [() => import(/* webpackChunkName: "23c4d3ab" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-spelling-235.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-spelling-235.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-spelling-235.json")],
  "2410385f": [() => import(/* webpackChunkName: "2410385f" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavism-d06.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavism-d06.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavism-d06.json")],
  "25716e5f": [() => import(/* webpackChunkName: "25716e5f" */ "@site/docs/resources/applets/index.md"), "@site/docs/resources/applets/index.md", require.resolveWeak("@site/docs/resources/applets/index.md")],
  "29714ff1": [() => import(/* webpackChunkName: "29714ff1" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-slovioski-2f2.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-slovioski-2f2.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-slovioski-2f2.json")],
  "2990b57c": [() => import(/* webpackChunkName: "2990b57c" */ "@generated/docusaurus-plugin-content-blog/default/p/articles-archive-ab0.json"), "@generated/docusaurus-plugin-content-blog/default/p/articles-archive-ab0.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/articles-archive-ab0.json")],
  "2a580491": [() => import(/* webpackChunkName: "2a580491" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-dialects-e2f.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-dialects-e2f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-dialects-e2f.json")],
  "2cfc4faa": [() => import(/* webpackChunkName: "2cfc4faa" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-relay-cfc.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-relay-cfc.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-relay-cfc.json")],
  "3093128c": [() => import(/* webpackChunkName: "3093128c" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-slovianto-21e.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-slovianto-21e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-slovianto-21e.json")],
  "36994c47": [() => import(/* webpackChunkName: "36994c47" */ "@generated/docusaurus-plugin-content-blog/default/__plugin.json"), "@generated/docusaurus-plugin-content-blog/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/__plugin.json")],
  "3720c009": [() => import(/* webpackChunkName: "3720c009" */ "@theme/DocTagsListPage"), "@theme/DocTagsListPage", require.resolveWeak("@theme/DocTagsListPage")],
  "3c00930a": [() => import(/* webpackChunkName: "3c00930a" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-slovio-811.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-slovio-811.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-slovio-811.json")],
  "3e725093": [() => import(/* webpackChunkName: "3e725093" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-word-formation-1fd.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-word-formation-1fd.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-word-formation-1fd.json")],
  "40f3d0b9": [() => import(/* webpackChunkName: "40f3d0b9" */ "@site/docs/learn/vocabulary/flavourisation.md"), "@site/docs/learn/vocabulary/flavourisation.md", require.resolveWeak("@site/docs/learn/vocabulary/flavourisation.md")],
  "42b35420": [() => import(/* webpackChunkName: "42b35420" */ "@site/articles/2024-07-09-diskusija-lj-nj.md?truncated=true"), "@site/articles/2024-07-09-diskusija-lj-nj.md?truncated=true", require.resolveWeak("@site/articles/2024-07-09-diskusija-lj-nj.md?truncated=true")],
  "435e97a6": [() => import(/* webpackChunkName: "435e97a6" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-grammar-098.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-grammar-098.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-grammar-098.json")],
  "439c3195": [() => import(/* webpackChunkName: "439c3195" */ "@site/articles/2024-07-09-diskusija-lj-nj-cyrl.md"), "@site/articles/2024-07-09-diskusija-lj-nj-cyrl.md", require.resolveWeak("@site/articles/2024-07-09-diskusija-lj-nj-cyrl.md")],
  "4928a6a4": [() => import(/* webpackChunkName: "4928a6a4" */ "@site/docs/learn/phonology.md"), "@site/docs/learn/phonology.md", require.resolveWeak("@site/docs/learn/phonology.md")],
  "4d48c340": [() => import(/* webpackChunkName: "4d48c340" */ "@site/docs/learn/grammar/nouns.md"), "@site/docs/learn/grammar/nouns.md", require.resolveWeak("@site/docs/learn/grammar/nouns.md")],
  "4de343fa": [() => import(/* webpackChunkName: "4de343fa" */ "@site/docs/resources/keyboards.md"), "@site/docs/resources/keyboards.md", require.resolveWeak("@site/docs/resources/keyboards.md")],
  "4e12b95c": [() => import(/* webpackChunkName: "4e12b95c" */ "@site/docs/learn/simple-grammar/index.md"), "@site/docs/learn/simple-grammar/index.md", require.resolveWeak("@site/docs/learn/simple-grammar/index.md")],
  "4ee8436f": [() => import(/* webpackChunkName: "4ee8436f" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-mary-russel-mitford-467.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-mary-russel-mitford-467.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-mary-russel-mitford-467.json")],
  "4fa18c1c": [() => import(/* webpackChunkName: "4fa18c1c" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-cyrillic-d27.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-cyrillic-d27.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-cyrillic-d27.json")],
  "50d5db4f": [() => import(/* webpackChunkName: "50d5db4f" */ "@site/docs/learn/introduction/history.md"), "@site/docs/learn/introduction/history.md", require.resolveWeak("@site/docs/learn/introduction/history.md")],
  "54e4843a": [() => import(/* webpackChunkName: "54e4843a" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-language-comparison-00d.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-language-comparison-00d.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-language-comparison-00d.json")],
  "570f2c8d": [() => import(/* webpackChunkName: "570f2c8d" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-language-379.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-language-379.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-language-379.json")],
  "572b5f69": [() => import(/* webpackChunkName: "572b5f69" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-latin-b61.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-latin-b61.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-latin-b61.json")],
  "58edaf8e": [() => import(/* webpackChunkName: "58edaf8e" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-syntax-67e.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-syntax-67e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-syntax-67e.json")],
  "5b265f0c": [() => import(/* webpackChunkName: "5b265f0c" */ "@site/docs/learn/introduction/constructed-slavic-languages.md"), "@site/docs/learn/introduction/constructed-slavic-languages.md", require.resolveWeak("@site/docs/learn/introduction/constructed-slavic-languages.md")],
  "5b868653": [() => import(/* webpackChunkName: "5b868653" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-old-church-slavonic-633.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-old-church-slavonic-633.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-old-church-slavonic-633.json")],
  "5c3b26dd": [() => import(/* webpackChunkName: "5c3b26dd" */ "@site/docs/learn/simple-grammar/level-2.md"), "@site/docs/learn/simple-grammar/level-2.md", require.resolveWeak("@site/docs/learn/simple-grammar/level-2.md")],
  "5cf510d1": [() => import(/* webpackChunkName: "5cf510d1" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-communication-8fd.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-communication-8fd.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-communication-8fd.json")],
  "5e95c892": [() => import(/* webpackChunkName: "5e95c892" */ "@theme/DocsRoot"), "@theme/DocsRoot", require.resolveWeak("@theme/DocsRoot")],
  "5e9f5e1a": [() => import(/* webpackChunkName: "5e9f5e1a" */ "@generated/docusaurus.config"), "@generated/docusaurus.config", require.resolveWeak("@generated/docusaurus.config")],
  "614d47b4": [() => import(/* webpackChunkName: "614d47b4" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-simplified-grammar-a1a.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-simplified-grammar-a1a.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-simplified-grammar-a1a.json")],
  "6444bfb6": [() => import(/* webpackChunkName: "6444bfb6" */ "@site/docs/learn/grammar/numerals.md"), "@site/docs/learn/grammar/numerals.md", require.resolveWeak("@site/docs/learn/grammar/numerals.md")],
  "658d62f3": [() => import(/* webpackChunkName: "658d62f3" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-numbers-158.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-numbers-158.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-numbers-158.json")],
  "65b53035": [() => import(/* webpackChunkName: "65b53035" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-proto-slavic-654.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-proto-slavic-654.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-proto-slavic-654.json")],
  "66471966": [() => import(/* webpackChunkName: "66471966" */ "@generated/docusaurus-plugin-content-blog/default/p/articles-113.json"), "@generated/docusaurus-plugin-content-blog/default/p/articles-113.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/articles-113.json")],
  "666eeb29": [() => import(/* webpackChunkName: "666eeb29" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-language-learning-36c.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-language-learning-36c.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-language-learning-36c.json")],
  "67aea5fa": [() => import(/* webpackChunkName: "67aea5fa" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-history-3db.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-history-3db.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-history-3db.json")],
  "68311b93": [() => import(/* webpackChunkName: "68311b93" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-design-criteria-042.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-design-criteria-042.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-design-criteria-042.json")],
  "6dfbb902": [() => import(/* webpackChunkName: "6dfbb902" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-auxiliary-languages-705.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-auxiliary-languages-705.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-auxiliary-languages-705.json")],
  "6f2aa787": [() => import(/* webpackChunkName: "6f2aa787" */ "@site/docs/learn/introduction/index.md"), "@site/docs/learn/introduction/index.md", require.resolveWeak("@site/docs/learn/introduction/index.md")],
  "73c27810": [() => import(/* webpackChunkName: "73c27810" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-hutsul-dfe.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-hutsul-dfe.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-hutsul-dfe.json")],
  "7c129beb": [() => import(/* webpackChunkName: "7c129beb" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-derivation-efe.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-derivation-efe.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-derivation-efe.json")],
  "7f64df0f": [() => import(/* webpackChunkName: "7f64df0f" */ "@site/docs/learn/vocabulary/word-formation.md"), "@site/docs/learn/vocabulary/word-formation.md", require.resolveWeak("@site/docs/learn/vocabulary/word-formation.md")],
  "80f173bd": [() => import(/* webpackChunkName: "80f173bd" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-introduction-4f1.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-introduction-4f1.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-introduction-4f1.json")],
  "814f3328": [() => import(/* webpackChunkName: "814f3328" */ "~blog/default/blog-post-list-prop-default.json"), "~blog/default/blog-post-list-prop-default.json", require.resolveWeak("~blog/default/blog-post-list-prop-default.json")],
  "8230f523": [() => import(/* webpackChunkName: "8230f523" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-conjunctions-67f.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-conjunctions-67f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-conjunctions-67f.json")],
  "835b0236": [() => import(/* webpackChunkName: "835b0236" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-personal-pronouns-cdf.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-personal-pronouns-cdf.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-personal-pronouns-cdf.json")],
  "8678711e": [() => import(/* webpackChunkName: "8678711e" */ "@site/docs/learn/orthography.md"), "@site/docs/learn/orthography.md", require.resolveWeak("@site/docs/learn/orthography.md")],
  "8988ddcf": [() => import(/* webpackChunkName: "8988ddcf" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-comparative-460.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-comparative-460.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-comparative-460.json")],
  "89a139fd": [() => import(/* webpackChunkName: "89a139fd" */ "@site/docs/learn/misc/numbers-1-10.md"), "@site/docs/learn/misc/numbers-1-10.md", require.resolveWeak("@site/docs/learn/misc/numbers-1-10.md")],
  "8b83d697": [() => import(/* webpackChunkName: "8b83d697" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-phonology-73f.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-phonology-73f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-phonology-73f.json")],
  "8dcc4d44": [() => import(/* webpackChunkName: "8dcc4d44" */ "@site/docs/learn/grammar/prepositions.md"), "@site/docs/learn/grammar/prepositions.md", require.resolveWeak("@site/docs/learn/grammar/prepositions.md")],
  "8ec24c89": [() => import(/* webpackChunkName: "8ec24c89" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-slavski-b3f.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-slavski-b3f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-slavski-b3f.json")],
  "8f300f0e": [() => import(/* webpackChunkName: "8f300f0e" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-international-vocabulary-e33.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-international-vocabulary-e33.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-international-vocabulary-e33.json")],
  "8f86807d": [() => import(/* webpackChunkName: "8f86807d" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-kashubian-fcb.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-kashubian-fcb.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-kashubian-fcb.json")],
  "9032ffef": [() => import(/* webpackChunkName: "9032ffef" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-pronunciation-d7e.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-pronunciation-d7e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-pronunciation-d7e.json")],
  "914bf1c6": [() => import(/* webpackChunkName: "914bf1c6" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-podlachian-949.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-podlachian-949.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-podlachian-949.json")],
  "980e11e4": [() => import(/* webpackChunkName: "980e11e4" */ "@site/docs/learn/vocabulary/derivation.md"), "@site/docs/learn/vocabulary/derivation.md", require.resolveWeak("@site/docs/learn/vocabulary/derivation.md")],
  "98ff08f5": [() => import(/* webpackChunkName: "98ff08f5" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavic-015.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavic-015.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-pan-slavic-015.json")],
  "9adf237d": [() => import(/* webpackChunkName: "9adf237d" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-interslavic-d30.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-interslavic-d30.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-interslavic-d30.json")],
  "9dd8a0d2": [() => import(/* webpackChunkName: "9dd8a0d2" */ "@site/src/pages/index.jsx"), "@site/src/pages/index.jsx", require.resolveWeak("@site/src/pages/index.jsx")],
  "9e4087bc": [() => import(/* webpackChunkName: "9e4087bc" */ "@theme/BlogArchivePage"), "@theme/BlogArchivePage", require.resolveWeak("@theme/BlogArchivePage")],
  "a3a73060": [() => import(/* webpackChunkName: "a3a73060" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-83e.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-83e.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-83e.json")],
  "a5656b7e": [() => import(/* webpackChunkName: "a5656b7e" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-adjectives-1a5.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-adjectives-1a5.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-adjectives-1a5.json")],
  "a6aa9e1f": [() => import(/* webpackChunkName: "a6aa9e1f" */ "@theme/BlogListPage"), "@theme/BlogListPage", require.resolveWeak("@theme/BlogListPage")],
  "a7456010": [() => import(/* webpackChunkName: "a7456010" */ "@generated/docusaurus-plugin-content-pages/default/__plugin.json"), "@generated/docusaurus-plugin-content-pages/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-pages/default/__plugin.json")],
  "a7bd4aaa": [() => import(/* webpackChunkName: "a7bd4aaa" */ "@theme/DocVersionRoot"), "@theme/DocVersionRoot", require.resolveWeak("@theme/DocVersionRoot")],
  "a94703ab": [() => import(/* webpackChunkName: "a94703ab" */ "@theme/DocRoot"), "@theme/DocRoot", require.resolveWeak("@theme/DocRoot")],
  "aba21aa0": [() => import(/* webpackChunkName: "aba21aa0" */ "@generated/docusaurus-plugin-content-docs/default/__plugin.json"), "@generated/docusaurus-plugin-content-docs/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/__plugin.json")],
  "acbe8585": [() => import(/* webpackChunkName: "acbe8585" */ "@site/docs/resources/applets/transliterator.md"), "@site/docs/resources/applets/transliterator.md", require.resolveWeak("@site/docs/resources/applets/transliterator.md")],
  "acecf23e": [() => import(/* webpackChunkName: "acecf23e" */ "~blog/default/blogMetadata-default.json"), "~blog/default/blogMetadata-default.json", require.resolveWeak("~blog/default/blogMetadata-default.json")],
  "ae441402": [() => import(/* webpackChunkName: "ae441402" */ "@site/docs/learn/grammar/syntax.md"), "@site/docs/learn/grammar/syntax.md", require.resolveWeak("@site/docs/learn/grammar/syntax.md")],
  "af9e5d51": [() => import(/* webpackChunkName: "af9e5d51" */ "@site/docs/resources/applets/intelligibility.md"), "@site/docs/resources/applets/intelligibility.md", require.resolveWeak("@site/docs/resources/applets/intelligibility.md")],
  "beebba32": [() => import(/* webpackChunkName: "beebba32" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-nouns-af3.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-nouns-af3.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-nouns-af3.json")],
  "bf21c73f": [() => import(/* webpackChunkName: "bf21c73f" */ "@site/docs/learn/grammar/conjunctions.md"), "@site/docs/learn/grammar/conjunctions.md", require.resolveWeak("@site/docs/learn/grammar/conjunctions.md")],
  "c4796dc5": [() => import(/* webpackChunkName: "c4796dc5" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-constructed-languages-0e8.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-constructed-languages-0e8.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-constructed-languages-0e8.json")],
  "c6faf655": [() => import(/* webpackChunkName: "c6faf655" */ "@site/docs/learn/grammar/adjectives.md"), "@site/docs/learn/grammar/adjectives.md", require.resolveWeak("@site/docs/learn/grammar/adjectives.md")],
  "c86b566b": [() => import(/* webpackChunkName: "c86b566b" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-game-e43.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-game-e43.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-game-e43.json")],
  "ca05a39a": [() => import(/* webpackChunkName: "ca05a39a" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-slavic-languages-b6f.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-slavic-languages-b6f.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-slavic-languages-b6f.json")],
  "cb567a96": [() => import(/* webpackChunkName: "cb567a96" */ "@site/docs/learn/grammar/pronouns.md"), "@site/docs/learn/grammar/pronouns.md", require.resolveWeak("@site/docs/learn/grammar/pronouns.md")],
  "ccc49370": [() => import(/* webpackChunkName: "ccc49370" */ "@theme/BlogPostPage"), "@theme/BlogPostPage", require.resolveWeak("@theme/BlogPostPage")],
  "cd948886": [() => import(/* webpackChunkName: "cd948886" */ "@site/docs/resources/index.md"), "@site/docs/resources/index.md", require.resolveWeak("@site/docs/resources/index.md")],
  "d058e478": [() => import(/* webpackChunkName: "d058e478" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-language-history-c82.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-language-history-c82.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-language-history-c82.json")],
  "d55cc31b": [() => import(/* webpackChunkName: "d55cc31b" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-orthography-acb.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-orthography-acb.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-orthography-acb.json")],
  "d5e2d1d0": [() => import(/* webpackChunkName: "d5e2d1d0" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-poetry-3d8.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-poetry-3d8.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-poetry-3d8.json")],
  "d6429a78": [() => import(/* webpackChunkName: "d6429a78" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-polabian-3cb.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-polabian-3cb.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-polabian-3cb.json")],
  "df203c0f": [() => import(/* webpackChunkName: "df203c0f" */ "@theme/DocTagDocListPage"), "@theme/DocTagDocListPage", require.resolveWeak("@theme/DocTagDocListPage")],
  "e67c2bf9": [() => import(/* webpackChunkName: "e67c2bf9" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-degrees-of-comparison-d6c.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-degrees-of-comparison-d6c.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-degrees-of-comparison-d6c.json")],
  "e8fae49b": [() => import(/* webpackChunkName: "e8fae49b" */ "@site/src/pages/501.mdx"), "@site/src/pages/501.mdx", require.resolveWeak("@site/src/pages/501.mdx")],
  "eefd7a70": [() => import(/* webpackChunkName: "eefd7a70" */ "@site/docs/learn/pronunciation.md"), "@site/docs/learn/pronunciation.md", require.resolveWeak("@site/docs/learn/pronunciation.md")],
  "f7944a6b": [() => import(/* webpackChunkName: "f7944a6b" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-linguistics-a9a.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-linguistics-a9a.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-linguistics-a9a.json")],
  "f9341436": [() => import(/* webpackChunkName: "f9341436" */ "@generated/docusaurus-plugin-content-docs/default/p/tags-inflection-297.json"), "@generated/docusaurus-plugin-content-docs/default/p/tags-inflection-297.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/tags-inflection-297.json")],
  "ff369e56": [() => import(/* webpackChunkName: "ff369e56" */ "@site/docs/learn/misc/personal-pronouns.md"), "@site/docs/learn/misc/personal-pronouns.md", require.resolveWeak("@site/docs/learn/misc/personal-pronouns.md")],
  "ffb6edcf": [() => import(/* webpackChunkName: "ffb6edcf" */ "@site/docs/learn/simple-grammar/level-1.md"), "@site/docs/learn/simple-grammar/level-1.md", require.resolveWeak("@site/docs/learn/simple-grammar/level-1.md")],};
