/*
 * AUTOGENERATED - DON'T EDIT
 * Your edits in this file will be overwritten in the next build!
 * Modify the docusaurus.config.js file at your site's root instead.
 */
export default {
  "title": "Interslavic",
  "url": "https://interslavic.fun",
  "baseUrl": "/",
  "trailingSlash": true,
  "onBrokenLinks": "warn",
  "onBrokenMarkdownLinks": "warn",
  "favicon": "img/favicon.ico",
  "organizationName": "medzuslovjansky",
  "projectName": "medzuslovjansky.github.io",
  "deploymentBranch": "gh-pages",
  "plugins": [
    "docusaurus-plugin-sass",
    "/home/runner/work/medzuslovjansky.github.io/medzuslovjansky.github.io/node_modules/@noomorph/docusaurus-search-local/lib/server/index.js",
    "/home/runner/work/medzuslovjansky.github.io/medzuslovjansky.github.io/src/plugins/webpack.js",
    [
      "@docusaurus/plugin-client-redirects",
      {}
    ]
  ],
  "presets": [
    [
      "@docusaurus/preset-classic",
      {
        "docs": {
          "routeBasePath": "",
          "sidebarPath": "/home/runner/work/medzuslovjansky.github.io/medzuslovjansky.github.io/sidebars.js",
          "editUrl": "https://github.com/medzuslovjansky/medzuslovjansky.github.io/edit/main/",
          "editLocalizedFiles": true,
          "showLastUpdateTime": true,
          "showLastUpdateAuthor": true,
          "remarkPlugins": [
            null,
            null
          ]
        },
        "blog": {
          "routeBasePath": "articles",
          "path": "./articles",
          "showReadingTime": true,
          "editUrl": "https://github.com/medzuslovjansky/medzuslovjansky.github.io/edit/main/",
          "editLocalizedFiles": true
        },
        "theme": {
          "customCss": "/home/runner/work/medzuslovjansky.github.io/medzuslovjansky.github.io/src/css/custom.scss"
        }
      }
    ]
  ],
  "i18n": {
    "defaultLocale": "en",
    "locales": [
      "en",
      "be",
      "bg",
      "cs",
      "hr",
      "mk",
      "pl",
      "ru",
      "sl",
      "sr-Cyrl",
      "uk"
    ],
    "path": "i18n",
    "localeConfigs": {}
  },
  "markdown": {
    "format": "mdx",
    "mermaid": false,
    "mdx1Compat": {
      "comments": true,
      "admonitions": true,
      "headingIds": true
    },
    "anchors": {
      "maintainCase": false
    }
  },
  "themeConfig": {
    "navbar": {
      "title": "Interslavic",
      "hideOnScroll": true,
      "logo": {
        "alt": "Logo",
        "src": "img/logo.png"
      },
      "items": [
        {
          "type": "doc",
          "docId": "learn/introduction/index",
          "position": "left",
          "label": "Learn"
        },
        {
          "type": "doc",
          "docId": "resources/index",
          "position": "left",
          "label": "Resources"
        },
        {
          "to": "/articles",
          "label": "Articles",
          "position": "left"
        },
        {
          "href": "https://interslavic-dictionary.com",
          "label": "Online Dictionary",
          "className": "navbar__item--cta-button",
          "position": "left"
        },
        {
          "type": "localeDropdown",
          "className": "navbar__item--locale-dropdown",
          "position": "right",
          "dropdownItemsBefore": [],
          "dropdownItemsAfter": []
        }
      ]
    },
    "footer": {
      "style": "dark",
      "links": [
        {
          "title": "Sitemap",
          "items": [
            {
              "label": "Home",
              "to": "/"
            },
            {
              "label": "Learn",
              "to": "/learn/introduction"
            },
            {
              "label": "Resources",
              "to": "/resources"
            },
            {
              "label": "FAQ",
              "to": "/learn/faq"
            }
          ]
        },
        {
          "title": "Community",
          "items": [
            {
              "label": "Facebook",
              "href": "https://facebook.com/groups/interslavic",
              "icon": {
                "src": "img/social/facebook-light.svg"
              }
            },
            {
              "label": "Discord",
              "href": "https://discord.com/invite/n3saqm27QW",
              "icon": {
                "src": "img/social/discord-light.svg"
              }
            },
            {
              "label": "YouTube",
              "href": "https://youtube.com/channel/UCShYXuD2TyJlYd9UWUUiYiA",
              "icon": {
                "src": "img/social/youtube-light.svg"
              }
            },
            {
              "label": "TikTok",
              "href": "https://tiktok.com/@interslavic",
              "icon": {
                "src": "img/social/tiktok-light.svg"
              }
            },
            {
              "label": "GitHub",
              "href": "https://github.com/medzuslovjansky",
              "icon": {
                "src": "img/social/github-light.svg"
              }
            }
          ]
        }
      ]
    },
    "docs": {
      "sidebar": {
        "hideable": true,
        "autoCollapseCategories": false
      },
      "versionPersistence": "localStorage"
    },
    "prism": {
      "theme": {
        "plain": {
          "color": "#393A34",
          "backgroundColor": "#f6f8fa"
        },
        "styles": [
          {
            "types": [
              "comment",
              "prolog",
              "doctype",
              "cdata"
            ],
            "style": {
              "color": "#999988",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "namespace"
            ],
            "style": {
              "opacity": 0.7
            }
          },
          {
            "types": [
              "string",
              "attr-value"
            ],
            "style": {
              "color": "#e3116c"
            }
          },
          {
            "types": [
              "punctuation",
              "operator"
            ],
            "style": {
              "color": "#393A34"
            }
          },
          {
            "types": [
              "entity",
              "url",
              "symbol",
              "number",
              "boolean",
              "variable",
              "constant",
              "property",
              "regex",
              "inserted"
            ],
            "style": {
              "color": "#36acaa"
            }
          },
          {
            "types": [
              "atrule",
              "keyword",
              "attr-name",
              "selector"
            ],
            "style": {
              "color": "#00a4db"
            }
          },
          {
            "types": [
              "function",
              "deleted",
              "tag"
            ],
            "style": {
              "color": "#d73a49"
            }
          },
          {
            "types": [
              "function-variable"
            ],
            "style": {
              "color": "#6f42c1"
            }
          },
          {
            "types": [
              "tag",
              "selector",
              "keyword"
            ],
            "style": {
              "color": "#00009f"
            }
          }
        ]
      },
      "darkTheme": {
        "plain": {
          "color": "#F8F8F2",
          "backgroundColor": "#282A36"
        },
        "styles": [
          {
            "types": [
              "prolog",
              "constant",
              "builtin"
            ],
            "style": {
              "color": "rgb(189, 147, 249)"
            }
          },
          {
            "types": [
              "inserted",
              "function"
            ],
            "style": {
              "color": "rgb(80, 250, 123)"
            }
          },
          {
            "types": [
              "deleted"
            ],
            "style": {
              "color": "rgb(255, 85, 85)"
            }
          },
          {
            "types": [
              "changed"
            ],
            "style": {
              "color": "rgb(255, 184, 108)"
            }
          },
          {
            "types": [
              "punctuation",
              "symbol"
            ],
            "style": {
              "color": "rgb(248, 248, 242)"
            }
          },
          {
            "types": [
              "string",
              "char",
              "tag",
              "selector"
            ],
            "style": {
              "color": "rgb(255, 121, 198)"
            }
          },
          {
            "types": [
              "keyword",
              "variable"
            ],
            "style": {
              "color": "rgb(189, 147, 249)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(98, 114, 164)"
            }
          },
          {
            "types": [
              "attr-name"
            ],
            "style": {
              "color": "rgb(241, 250, 140)"
            }
          }
        ]
      },
      "additionalLanguages": [],
      "magicComments": [
        {
          "className": "theme-code-block-highlighted-line",
          "line": "highlight-next-line",
          "block": {
            "start": "highlight-start",
            "end": "highlight-end"
          }
        }
      ]
    },
    "colorMode": {
      "defaultMode": "light",
      "disableSwitch": false,
      "respectPrefersColorScheme": false
    },
    "metadata": [],
    "tableOfContents": {
      "minHeadingLevel": 2,
      "maxHeadingLevel": 3
    }
  },
  "baseUrlIssueBanner": true,
  "future": {
    "experimental_storage": {
      "type": "localStorage",
      "namespace": false
    },
    "experimental_router": "browser"
  },
  "onBrokenAnchors": "warn",
  "onDuplicateRoutes": "warn",
  "staticDirectories": [
    "static"
  ],
  "customFields": {},
  "themes": [],
  "scripts": [],
  "headTags": [],
  "stylesheets": [],
  "clientModules": [],
  "tagline": "",
  "titleDelimiter": "|",
  "noIndex": false
};
